<template>
  <div :class="getClasss">
    <breadcrumb :breadStaticData="breadStaticData" :linkData="linkData" :thirdStaticData="thirdStaticData"></breadcrumb>
    <div class="page-table">
      <div class="title-search">
        <el-form :inline="true" :model="searchForm" class="demo-form-inline">
          <div class="title-search-line">
            <el-form-item label="产品名称:">
              <el-input size="small" v-model="searchForm.productName" placeholder="请输入产品名称" clearable></el-input>
            </el-form-item>
            <el-form-item label="产品生产批次:">
              <el-input size="small" v-model="searchForm.docNum" placeholder="请输入产品生产批次" clearable></el-input>
            </el-form-item>
            <el-form-item label="批次开始生产日期:">
              <el-date-picker
                class="my-date-range"
                v-model="timeRange"
                @change="timeChange"
                type="daterange"
                align="right"
                unlink-panels
                size="small"
                range-separator="到"
                start-placeholder="开始日期"
                end-placeholder="结束日期">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="收获日期:">
              <el-date-picker
                class="my-date-range"
                v-model="timeRange2"
                @change="timeChange2"
                type="daterange"
                align="right"
                unlink-panels
                size="small"
                range-separator="到"
                start-placeholder="开始日期"
                end-placeholder="结束日期">
              </el-date-picker>
            </el-form-item>
            <el-button type="primary" size="small" @click="getData(true)">查询</el-button>
          </div>
        </el-form>
      </div>
      <div class="title-tab">
        <div class="title-tab-btns">
          <el-button type="primary" size="small" icon="el-icon-plus" @click="add">新增</el-button>
          <el-button type="primary" size="small" icon="el-icon-download" @click="download">导出</el-button>
        </div>
      </div>
      <el-table 
        ref="table" 
        class="my-table"
        size="small" 
        :data="listData" 
        highlight-current-row 
        border
        v-loading="loading"  
        :element-loading-text="loadtext" 
        :height="tableHeight"
        v-bind="$attrs"
        v-on="$listeners"
      >
        <el-table-column align="center" type="index" width="70" label="序号">
          <template slot-scope="scope">
            <span>{{
              pageparm
                ? (pageparm.page - 1) * pageparm.limit +
                  scope.$index +
                  1
                : scope.$index + 1
            }}</span>
          </template>
        </el-table-column>
        
        <el-table-column
          v-for="(headData, index) in tableHead"
          :key="index"
          :prop="headData.prop"
          :label="headData.label"
          :width="headData.width"
          :align="headData.align || 'center'"
          :header-align="headData.headerAlign || 'center'"
          :min-width="headData.minWidth"
          :show-overflow-tooltip="false"
        >
          <template slot-scope="scope">
            {{ scope.row[headData.prop] || '' }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" fixed="right" width="150">
          <template slot-scope="scope">
            <el-button size="small" type="text" @click="view(scope.row, scope.$index)">查看</el-button>
            <el-button size="small" type="text" @click="edit(scope.row, scope.$index)">修改</el-button>
            <el-button size="small" type="text" @click="del(scope.row, scope.$index)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页组件 -->
      <pagination v-bind:child-msg="pageparm" @refreshTable="refreshTable"></pagination>
    </div>
    <farm-edit ref="editForm" @getData="getData"></farm-edit>
  </div>
</template>
<script>
  import pagination from '@/components/pagination.vue'
  import breadcrumb from '@/components/breadcrumb.vue'
  import farmEdit from './farmEdit.vue'
  import * as XLSX from "xlsx"
  import { request, utils, CONSTPARAMS, baseValidate } from '@/utils/baseUtils'
  export default {
    name: 'farmList',
    components: {
      pagination,
      breadcrumb,
      farmEdit
    },
    data() {
      let title = '日常农事或加工记录'
      return {
        timeRange: '',
        timeRange2: '',
        loadtext: '加载中',
        breadStaticData:['生产档案管理', title],
        searchForm: {
          page: CONSTPARAMS.page,
          limit: CONSTPARAMS.limit,
          bodyId: utils.getBodyId(),
          productName: '',
          docNum: '',
          startTime: '',
          endTime: '',
          harvestStartTime: '',
          harvestEndTime: '',
        },
        urlName: 'findallfarmingmanagement',
        //产品名称、产品生产批次、次批开始生产日期、日常农事日期、日常农事名称、天气、劳作人、负责人、操作（修改、删除、查看）
        tableHead: [{
          prop: 'productName',
          label: '产品名称'
        },{
          prop: 'docNum',
          label: '产品生产批次',
          width: 150
        },{
          prop: 'startProductionDate',
          label: '次批开始生产日期',
          width: 100
        },{
          prop: 'farmingDate',
          label: '日常农事日期',
          width: 100
        },{
          prop: 'managementName',
          label: '日常农事名称'
        },{
          prop: 'weather',
          label: '天气'
        },{
          prop: 'worker',
          label: '劳作人'
        },{
          prop: 'head',
          label: '负责人'
        }],
        pageName: title,
        tableName: `${title}列表`,
        linkData:[],
        thirdStaticData:[],
        loading: false,
        tableHeight: null,
        listData: [],
        pageparm: {
          page: CONSTPARAMS.page,
          limit: CONSTPARAMS.limit,
          total: 0
        },
        getClasss: utils.getClientFlag() ? 'page-contain my-table-list' : 'page-contain'
      }
    },
    created() {
      this.initTable()
    },
    mounted(){
      this.getTopHeight()
    },
    methods: {
      download(){
        let arr = this.listData,
        tableData = [['序号', '产品名称', '产品生产批次', "次批开始生产日期", "日常农事日期",  "日常农事名称", "天气", "劳作人", "负责人"]] // 表格表头
        if(!arr || !arr.length){
          this.message('暂无数据，无法导出！', "warning")
          return
        }
        arr.forEach((item, index) => {
          let rowData = []
          rowData = [
            index + 1,
            item.productName,
            item.docNum,
            item.startProductionDate,
            item.farmingDate,
            item.managementName,
            item.weather,
            item.worker,
            item.head,
          ]
          tableData.push(rowData)
        })
        this.loading = true
        this.loadtext = '导出中'
        let workSheet = XLSX.utils.aoa_to_sheet(tableData);
        let bookNew = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(bookNew, workSheet, '数据') // 工作簿名称
        let name = this.pageName + ' ' + utils.dateFormat(new Date(), "yyyy-MM-dd HH时mm分ss秒") + '.xlsx'
        XLSX.writeFile(bookNew, name) 
        this.loadtext = '加载中'
        this.loading = false
      },
      timeChange(val){
        let startTime = '',
          endTime = ''
        if(val){
          startTime = utils.dateFormat(val[0])
          endTime = utils.dateFormat(val[1])
        }
        this.searchForm.startTime = startTime
        this.searchForm.endTime = endTime
      },
      timeChange2(val){
        let startTime = '',
          endTime = ''
        if(val){
          startTime = utils.dateFormat(val[0])
          endTime = utils.dateFormat(val[1])
        }
        this.searchForm.harvestStartTime = startTime
        this.searchForm.harvestEndTime = endTime
      },
      async initTable(){
        this.getData()
      },
      async add(){
        // let id = await utils.getNewId(),
        //   obj = {
        //   ...this.initForm,
        //   batchId: id
        // }
        this.$refs.editForm.initData(`新增${this.pageName}`, 'add')
      },
      view(data, index){
        this.getInfo('look', data, index)
      },
      edit(data, index){
        this.getInfo('edit', data, index)
      },
      getInfo(type, data){
        let _this = this
        request.findfarmingmanagement({
          managementId: data.managementId
        }).then(res => {
          if(res.code == 200){
            let result = res.data
            if(!result){
              _this.message('此条数据信息获取为空！', "error")
              return
            }
            result = {
              ...result,
              imageUrl: result.imageUrl ? result.imageUrl.split(',') : [],
              videoUrl: result.videoUrl ? result.videoUrl.split(',') : [],
              farmingType: utils.constGetSelLabel('farmingType', result.farmingType),
              startProductionDate: result.startProductionDate ? utils.dateFormat(result.startProductionDate, 'yyyy-MM-dd') : '',
              farmingDate: result.farmingDate ? utils.dateFormat(result.farmingDate, 'yyyy-MM-dd') : '',
              show: result.show == '01' ? true : false
            }
            delete result.createTime
            this.$refs.editForm.initData(`${type == 'edit' ? '修改' : '查看'}${this.pageName}`, type, result)
          } else {
            _this.message(res.msg || '', "error")
          }
        }).catch(e => {
          console.log(e)
        })
      },
      del(data){
        let _this = this
        _this.$confirm(`确认要删除${data.productName || ''}这条记录吗？`, "提示", {
          cancelButtonText: "取消",
          confirmButtonText: "确定",
          type: "warning",
        })
        .then(() => {
          request.deletefarmingmanagement({
            managementId: data.managementId
          }).then(res => {
            if(res.code == 200){
              _this.getData()
              _this.message('删除成功！', "success");
            }else{
              _this.message(res.msg || '', "error");
            }
          }).catch(e => {
            console.log(e)
          })
        }).catch(() => {})
      },
      getTopHeight(){
        let getTopHeight = utils.getTopHeight()
        this.tableHeight = `calc(100% - ${getTopHeight}px)`
      },
      refreshTable(parm) {
        this.searchForm.page = parm.page
        this.searchForm.limit = parm.limit
        this.getData()
      },
      getData(flag = false){
        let _this = this
        if(flag){
          this.searchForm.page = CONSTPARAMS.page
          this.searchForm.limit = CONSTPARAMS.limit
        }
        _this.loading = true
        request[_this.urlName](_this.searchForm).then(res => {
          _this.loading = false
          if(res.code == 200){
            let lists = res.data.list || []
            lists = lists.map(item => {
              return {
                ...item,
                startProductionDate: utils.dateFormat(item.startProductionDate, 'yyyy-MM-dd'),
                farmingDate: utils.dateFormat(item.farmingDate, 'yyyy-MM-dd'),
              }
            })
            _this.listData = lists
            _this.pageparm.page = _this.searchForm.page
            _this.pageparm.total = res.data.total || 0
            _this.$nextTick(() => {
              _this.$refs.table && _this.$refs.table.doLayout()
            })
          }else{
            _this.message(res.msg || '', "error")
          }
        }).catch(e => {
          console.log(e)
          _this.loading = false
        })
      },

    },
  }
</script>
<style lang="scss" scoped>
</style>