<template>
  <el-dialog
    class="my-dialog"
    :title="title"
    width="900px"
    :close-on-click-modal="false"
    :visible.sync="closeFlag"
    :before-close="closeModel"
    append-to-body
    destroy-on-close
    :show-close="!loading"
    center
  >
    <div class="forms-form" v-loading="loading" element-loading-text="保存中" >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        size="middle"
        label-width="180px"
      >
        <div>
          <el-row>
            <el-col :span="24">
              <el-form-item label="产品生产批次编号：" prop="docNum">
                <p v-if="editType == 'look' || editType == 'edit'" class="view-cont">{{ ruleForm.docNum }}</p>
                <el-input :disabled="true" v-else :class="!hasPid ? 'my-input-btn' : ''" v-model="ruleForm.docNum" placeholder="请选择产品生产批次编号">
                  <el-button v-if="!hasPid" slot="append" @click="chooseType('getbatchList')" type="primary">选择</el-button>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="产品名称：" prop="productName">
                <p v-if="editType == 'look'" class="view-cont">{{ ruleForm.productName }}</p>
                <el-input :disabled="true" clearable v-else v-model="ruleForm.productName" placeholder="自动生成"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="日常记录类型：" prop="farmingType">
                <p v-if="editType == 'look' || editType == 'edit'" class="view-cont">{{ ruleForm.farmingType }}</p>
                <el-select v-else v-model="ruleForm.farmingType" @change="farmingTypeChange" class="full-width" clearable :placeholder="editType == 'look' ? '' : '请选择日常记录类型'" :disabled="editType == 'look'">
                  <el-option
                    v-for="(item, index) in selectObj.farmingType"
                    :key="index"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="日常农事或加工名称：" prop="managementName">
                <p v-if="editType == 'look'" class="view-cont">{{ ruleForm.managementName }}</p>
                <el-input clearable v-else v-model="ruleForm.managementName" placeholder="请输入日常农事或加工名称" maxlength=32 show-word-limit></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="日常农事或加工日期：" prop="farmingDate">
                <p v-if="editType == 'look'" class="view-cont">{{ ruleForm.farmingDate }}</p>
                <el-date-picker 
                  v-else
                  :disabled="editType == 'look'"
                  class="date-width"
                  v-model="ruleForm.farmingDate"
                  type="date"
                  value-format="yyyy-MM-dd"
                  placeholder="请选择日常农事或加工日期">
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="日常农事或加工过程描述：" prop="farmingRemark">
                <p v-if="editType == 'look'" class="view-cont">{{ ruleForm.farmingRemark }}</p>
                <el-input clearable v-else v-model="ruleForm.farmingRemark" placeholder="请输入日常农事或加工过程描述" maxlength=128 show-word-limit type="textarea" :rows="3"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="天气：" prop="weather">
                <p v-if="editType == 'look'" class="view-cont">{{ ruleForm.weather }}</p>
                <el-input clearable v-else v-model="ruleForm.weather" placeholder="请输入天气" maxlength=32 show-word-limit></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="劳作人：" prop="worker">
                <p v-if="editType == 'look'" class="view-cont">{{ ruleForm.worker }}</p>
                <el-input clearable v-else v-model="ruleForm.worker" placeholder="请输入劳作人" maxlength=32 show-word-limit></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="负责人：" prop="head">
                <p v-if="editType == 'look'" class="view-cont">{{ ruleForm.head }}</p>
                <el-input clearable v-else v-model="ruleForm.head" placeholder="请输入负责人" maxlength=16 show-word-limit></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="日常农事或加工过程图片：" prop="imageUrl" class="my-upload-files">
                <el-image 
                  v-for="(item, index) in ruleForm.imageUrl"
                  :key="index"
                  style="width: 100px; height: 100px"
                  :src="item" 
                  :preview-src-list="ruleForm.imageUrl">
                </el-image>
                <div v-if="editType == 'look' && !ruleForm.imageUrl.length" class="no-data">无图片</div>
                <div v-if="editType != 'look'" @click="addPics" class="picture-card-plus"><i class="el-icon-plus"></i></div>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="日常农事或加工过程视频：" prop="videoUrl" class="my-upload-files">
                <div class="all-video" v-for="(item, index) in ruleForm.videoUrl" :key="index">
                  <video :src="item" class="avatar" controls="controls">您的浏览器不支持视频播放</video>
                </div>
                <div v-if="editType == 'look' && !ruleForm.videoUrl.length" class="no-data">无视频</div>
                <div v-if="editType != 'look'" @click="addVids" class="picture-card-plus"><i class="el-icon-plus"></i></div>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="备注：" prop="remark">
                <p v-if="editType == 'look'" class="view-cont">{{ ruleForm.remark }}</p>
                <el-input clearable v-else v-model="ruleForm.remark" placeholder="请输入备注" maxlength=128 show-word-limit type="textarea" :rows="3"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="" label-width="150px">
                <el-checkbox :disabled="editType == 'look'" v-model="ruleForm.show">是否扫码展示</el-checkbox>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </el-form>
    </div>
    <div class="forms-btns" slot="footer">
      <el-button type="primary" v-if="editType != 'look'" :loading="loading" size="small" @click="submitForm()">保存</el-button>
      <el-button @click="closeModel" :disabled="loading" size="small"> {{ editType == 'look' ? '关闭' : '取消' }}</el-button>
    </div>
    <pics ref="pics" dictCode="410011" :businessCode="ruleForm.managementId" :imgSize="0.5" propName="imageUrl" storeTableName="tableName" @getpics="getpics"></pics>
    <vids ref="vids" dictCode="410012" :businessCode="ruleForm.managementId" propName="videoUrl" storeTableName="tableName" @getpics="getpics"></vids>
    <batchList ref="getbatchList" @getbatchlist="getbatchList"  titleName="产品批次档案"></batchList>
  </el-dialog>
</template>
<script>
import { request, utils, CONSTPARAMS, baseValidate } from '@/utils/baseUtils'
import batchList from '@/views/common/batchList.vue'
import pics from '@/views/common/pics.vue'
import vids from '@/views/common/vids.vue'
export default {
  name: "farmEdit",
  components: {
    pics,
    vids,
    batchList,
  },
  props:{
    hasPid: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {   
      rules: {
        docNum: [{ required: true,  trigger: "change", message: '请选择产品生产批次编号'}],
        farmingType: [{ required: true,  trigger: "change", message: '请选择常农事类型'}],
        managementName: [{ required: true,  trigger: "change", message: '请输入日常农事或加工名称'}],
        farmingDate: [{ required: true,  trigger: "change", message: '请选择日常农事或加工日期'}]
      },
      closeFlag: false,
      loading: false,
      title: '',
      editType: '',
      ruleForm: {},
      initForm: {
        managementId: '',
        docId: '',
        docNum: '', 
        productName: '', 
        farmingType: '',
        farmingTypeName: '',
        managementName: '',
        farmingDate: '',
        farmingRemark: '',
        weather: '',
        worker: '',
        head: '',
        imageUrl: [],
        videoUrl: [],
        tableName: '',
        remark: '',
        show: true
      },
      selectObj: {
        farmingType: utils.constTranSels('farmingType')
        // farmingType: CONSTPARAMS.farmingType
      },
      selList: []
    };
  },
  created() {
	},
  mounted() {
  },
  methods: {
    farmingTypeChange(val){
      // this.ruleForm.managementName = utils.getConstToVal('farmingType', val)
      this.ruleForm.managementName = val
    },
    getbatchList(data){
      this.ruleForm.docId = data.docId || ''
      this.ruleForm.docNum = data.docNum || ''
      this.ruleForm.productName = data.productName || ''
    },
    chooseType(name){
      this.$refs[name].initData()
    },
    addPics(){
      this.$refs.pics.initData(this.ruleForm.imageUrl)
    },
    addVids(){
      this.$refs.vids.initData(this.ruleForm.videoUrl)
    },
    getpics(data){
      this.ruleForm[data.propName] = data.pics
      this.ruleForm[data.storeTableName] = data.tableName
      this.$refs.ruleForm.validateField(data.propName);
    },
    async initData(title, editType, ruleForm, transobj = {}){
      if(editType == 'add'){
        let id = await utils.getNewId(),
          obj = {
          ...this.initForm,
          ...transobj,
          managementId: id,
        }
        this.ruleForm = obj
      }else{
        this.ruleForm = ruleForm
      }
      this.title = title
      this.editType = editType
      this.closeFlag = true
      this.resetForm()
      editType == 'add' && this.initAdd()
      editType == 'edit' && this.initEdit()
      editType == 'look' && this.initLook()
    },
    initAdd(){
    },
    initEdit(){
    },
    initLook(){
      this.initEdit()
    },
    async submitForm(flag = false) {
      let _this = this,
        editType = this.editType,
        formName = 'ruleForm',
        mesInfo = {
          add: "新增成功！",
          edit: "修改成功！"
        },
        newId = flag ? await utils.getNewId() : ''
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let ways = editType == 'add' ? 'savefarmingmanagement' : 'updatefarmingmanagement',
            target = {
            ..._this.ruleForm,
            imageUrl: _this.ruleForm.imageUrl?.join(',') || '',
            videoUrl: _this.ruleForm.videoUrl?.join(',') || '',
            farmingDate: _this.ruleForm.farmingDate ? _this.ruleForm.farmingDate + ' 00:00:00' : '',
            show: _this.ruleForm.show ? '01' : '02',
            farmingType: utils.constGetSelLabel('farmingType', _this.ruleForm.farmingType, 'label', 'value'),
          }
          _this.loading = true
          request[ways](target).then((res) => {
            if(res.code == 200){
              _this.message(mesInfo[editType], "success")
              if(flag){
                _this.ruleForm.useId = newId
              }else{
                _this.closeModel()
                _this.$emit("getData", editType == 'add' ? true : false)
              }
            }else{
              _this.message(res.msg || '', "error")
            }
            _this.loading = false
          }).catch(e => {
            _this.loading = false
          })
        } else {
          _this.message('请先完善信息！', "warning")
          return false
        }
      });
    },
    closeModel() {
      this.closeFlag = false
      // this.$emit("closeModel", false);
    },
    resetForm(formName = 'ruleForm') {
      this.$refs[formName]?.resetFields();
    }
  },
  watch: {
    // 'ruleForm.smpOrgid': {
    //   handler(val){
    //     this.ruleForm.smpOrgid = val
    //     this.queryData()
    //   },
    //   deep: true
    // }
  }
};
</script>

<style lang="scss" scoped>
  .my-dialog ::v-deep .el-dialog {
    margin-top: 5vh !important;
  }
</style>


